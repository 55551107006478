import { connect } from 'react-redux'
import { updateDate, checkout, removeCartItem } from '@ui/shared'
import CartComponent from './../pages/Home/components/CartComponent'

const mapStateToProps = (state, ownProps) => {
  return {
    cart: state.shop.cart,
    totalPrice: state.shop.totalPrice,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateDate: (cartItem, index, startDate, endDate, daysOfRent) =>
      dispatch(updateDate(cartItem, index, startDate, endDate, daysOfRent)),
    checkout: () => dispatch(checkout()),
    removeCartItem: (index) => dispatch(removeCartItem(index)),
  }
}

const CartContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CartComponent)

export default CartContainer
