import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authHeader } from '@ui/shared'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ItemComponent from './ItemComponent'
import Alert from '@material-ui/lab/Alert'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import { useFetch } from '@ui/shared'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  alert: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },

  grid: {
    marginBottom: theme.spacing(4),
  },
  dateWrap: {
    justifySelf: 'center',
  },
  select: {
    minWidth: '250px',
  },
})
const categoriesEndPoint = `${process.env.REACT_APP_API_ENTRYPOINT}/fitness_categories`
function ShopComponent(props) {
  const { classes, inventory, cart, addToCart, updateDate } = props

  const dispatch = useDispatch()

  const handleChange = (value) => {
    setQueryParams({
      ...queryParams,
      'model.category': `model.category=${value}`,
    })
  }

  const auth = useSelector((state) => state.auth)
  const [queryParams, setQueryParams] = useState({
    'model.category': '',
  })

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]

  const categoriesRes = useFetch(categoriesEndPoint, options, args)

  function getData() {
    return (dispatch) => {
      fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}/fitness_equipments/availableForHire?${queryParams['model.category']}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            ...authHeader(),
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          dispatch({
            type: 'FETCH_DATA',
            inventory: res,
          })
        })
    }
  }

  React.useEffect(() => {
    queryParams && dispatch(getData())
    dispatch(getData())
    // eslint-disable-next-line
  }, [queryParams])

  return (
    <div className={classes.root}>
      <Grid container spacing={16}>
        <div className={classes.grid}>
          <FormControl variant="outlined" className={classes.select}>
            <InputLabel>Wybierz kategorie</InputLabel>
            <Select
              onChange={(e) => handleChange(e.target.value)}
              label="Wybierz kategorie"
            >
              <MenuItem value={''}>Wszystkie </MenuItem>
              {categoriesRes.state.data &&
                categoriesRes.state.data.content.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        {inventory?.content?.length === 0 ? (
          <Alert
            variant="outlined"
            severity="warning"
            className={classes.alert}
          >
            Brak maszyn w tym terminie/miejscu
          </Alert>
        ) : (
          <Alert variant="outlined" severity="info" className={classes.alert}>
            Wybierz maszynę i przejdź do koszyka
          </Alert>
        )}
        {inventory?.content?.map((item, i) => (
          <ItemComponent
            isLink={true}
            item={item}
            addToCart={addToCart}
            updateDate={updateDate}
            key={item.id}
            cart={cart[i]}
          />
        ))}
      </Grid>
    </div>
  )
}

ShopComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  inventory: PropTypes.array.isRequired,
  addToCart: PropTypes.func.isRequired,
}

export default withStyles(styles)(ShopComponent)
