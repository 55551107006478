import { connect } from 'react-redux'
import { updateDate, checkout, removeCartItem } from '@ui/shared'
import CartComponentContent from './../pages/Home/components/CartComponentContent'

const mapStateToProps = (state, ownProps) => {
  return {
    cart: state.shop.cart,
    totalPrice: state.shop.totalPrice,
    cartDetails: ownProps.cartDetails,
    isLastStep: ownProps.isLastStep,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateDate: (cartItem, index, startDate, endDate, daysOfRent) =>
      dispatch(updateDate(cartItem, index, startDate, endDate, daysOfRent)),
    checkout: () => dispatch(checkout()),
    removeCartItem: (index) => dispatch(removeCartItem(index)),
  }
}

const CartContainerStep2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(CartComponentContent)

export default CartContainerStep2
