import React from 'react'
import CartContainerStep2 from './../../../containers/CartContainerStep2'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {},

  stepper: {
    padding: theme.spacing(3, 0, 3),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },

  cart: {
    position: 'relative',
    right: '6px',
  },
}))

export default function OrderComponent(props) {
  const classes = useStyles()

  return (
    <main className={classes.layout}>
      <CartContainerStep2 cartDetails={true} />
    </main>
  )
}
