import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import DateRangeIcon from '@material-ui/icons/DateRange'
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css'
import moment from 'moment'
import {} from 'moment-range'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import { polishPlural } from '@ui/shared'

const styles = (theme) => ({
  title: {
    width: 200,
    height: 200,
  },
  textField: {
    marginLeft: 20,
    marginRight: 15,
    marginBottom: 20,
  },
  chip: {
    marginRight: 20,
  },
  itemName: {
    marginRight: 15,
  },
  formControl: {
    marginTop: theme.spacing(3),
  },
  card: {
    maxWidth: 300,
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    backgroundSize: 'contain',
  },
})

function CartItemComponent(props) {
  const {
    classes,
    cartItem,
    updateDate,
    removeCartItem,
    removeCartItemIndex,
    cartDetails,
    isLastStep,
  } = props

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const [open, setOpen] = React.useState(false)
  const [dateRange, setDateRange] = React.useState({
    value: moment.range(moment(cartItem?.startDate), moment(cartItem?.endDate)),
  })

  const stateDefinitions = {
    available: {
      color: null,
      label: 'Dostępne',
    },
    unavailable: {
      selectable: false,
      color: '#78818b',
      label: 'Niedostępne',
    },
  }

  const handleSelect = (range, states) => {
    setDateRange({
      value: range,
      states: states,
    })
  }

  const daysOfRent = moment
    .duration(
      moment(dateRange.value.end)
        .startOf('day')
        .diff(moment(dateRange.value.start).startOf('day'))
    )
    .asDays()

  const discount = useSelector(
    (state) => state.user.profile.fitnessCustomer.discountLevel.discountRate
  )
  const totalPrice = useSelector((state) => state.shop.totalPrice)

  return (
    <div>
      <ListItem key={cartItem.id} style={cartDetails && { display: 'block' }}>
        <Chip
          color="primary"
          className={classes.chip}
          label={removeCartItemIndex + 1}
        />
        {cartDetails && (
          <div className={classes.card}>
            <CardMedia
              className={classes.media}
              image={`${process.env.REACT_APP_API_ENTRYPOINT}${cartItem.pictureURL}`}
              title={cartItem.name}
            />
          </div>
        )}
        <Typography
          variant={cartDetails ? ' headline' : 'body2'}
          component={cartDetails && 'h2'}
          className={classes.itemName}
          gutterBottom
        >
          {cartItem.name}
        </Typography>
        <Typography
          variant={cartDetails ? 'headline' : 'body2'}
          component={cartDetails && 'h3'}
          className={classes.itemPrice}
          gutterBottom
        >
          {cartDetails ? (
            <div>
              <p>
                {(
                  (cartItem.unitPrice * cartItem.daysOfRent) /
                  100
                ).toLocaleString('pl-PL', {
                  style: 'currency',
                  currency: 'PLN',
                })}{' '}
                Wynajem maszyny za {cartItem.daysOfRent}
                {polishPlural(' dzień ', ' dni ', ' dni ', cartItem.daysOfRent)}
              </p>
              <p>
                {(cartItem.bail / 100).toLocaleString('pl-PL', {
                  style: 'currency',
                  currency: 'PLN',
                })}{' '}
                Kaucja zwrotna
              </p>

              {discount !== 0 && (
                <p>
                  -
                  {((totalPrice * (discount / 100)) / 100).toLocaleString(
                    'pl-PL',
                    {
                      style: 'currency',
                      currency: 'PLN',
                    }
                  )}{' '}
                  Zniżka
                </p>
              )}
            </div>
          ) : (
            <div>
              {(cartItem.price / 100).toLocaleString('pl-PL', {
                style: 'currency',
                currency: 'PLN',
              })}
            </div>
          )}
        </Typography>

        {!isLastStep && (
          <>
            <Tooltip title="Update Date" placement="top">
              <IconButton
                color="primary"
                aria-label="Add to shopping cart"
                className={classes.icon}
                onClick={handleClickOpen}
              >
                <DateRangeIcon />
              </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
              <DialogContent>
                <DateRangePicker
                  firstOfWeek={1}
                  numberOfCalendars={2}
                  selectionType="range"
                  minimumDate={new Date()}
                  stateDefinitions={stateDefinitions}
                  dateStates={cartItem?.dateRanges}
                  defaultState="available"
                  showLegend={true}
                  value={dateRange.value}
                  onSelect={handleSelect}
                />
              </DialogContent>
              <DialogActions>
                {dateRange.value && (
                  <>
                    <Button
                      onClick={() =>
                        updateDate(
                          cartItem,
                          removeCartItemIndex,
                          dateRange.value.start,
                          dateRange.value.end,
                          daysOfRent
                        )
                      }
                      color="primary"
                      variant="contained"
                    >
                      Zmień
                    </Button>
                  </>
                )}
              </DialogActions>
            </Dialog>
            <Tooltip title="Usuń" placement="top">
              <IconButton
                color="secondary"
                className={classes.icon}
                onClick={() => removeCartItem(removeCartItemIndex)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </ListItem>
      <hr />
    </div>
  )
}

CartItemComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  cartItem: PropTypes.object.isRequired,
  updateDate: PropTypes.func.isRequired,
  removeCartItem: PropTypes.func.isRequired,
  removeCartItemIndex: PropTypes.number.isRequired,
}

export default withStyles(styles)(CartItemComponent)
