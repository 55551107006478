import { connect } from 'react-redux'
import { addToCart } from '@ui/shared'
import EquipmentComponent from './../pages/Home/components/EquipmentComponent'

const mapStateToProps = (state, ownProps) => {
  return {
    inventory: state.shop.inventory,
    cart: state.shop.cart,
    equipmentId: ownProps.equipmentId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addToCart: (item) => dispatch(addToCart(item)),
  }
}

const ShopItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentComponent)

export default ShopItemContainer
