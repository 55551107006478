import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import MobileStepper from '@material-ui/core/MobileStepper'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css'
import './calendar.css'
import moment from 'moment'
import {} from 'moment-range'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme) => ({
  card: {
    maxWidth: 300,
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    backgroundSize: 'contain',
  },
  button: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  wrapper: {
    marginTop: 20,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 'auto',
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
  rightIcon: {
    float: 'right',
    cursor: 'pointer',
  },
})

function ItemComponent(props) {
  const { classes, item, addToCart, cart, isLink } = props

  const plns = (item?.dailyPrice / 100).toLocaleString('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  })

  const [activeStep, setActiveStep] = React.useState(0)

  const tutorialSteps = item?.photos

  const maxSteps = item?.photos?.length

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const [open, setOpen] = React.useState(false)
  const [dateRange, setDateRange] = React.useState({
    value: moment.range(moment(cart?.startDate), moment(cart?.endDate)),
  })

  const stateDefinitions = {
    available: {
      color: null,
      label: 'Dostępne',
    },
    unavailable: {
      selectable: false,
      color: '#78818b',
      label: 'Niedostępne',
    },
  }

  const dateRanges =
    Array.isArray(item.bookedPeriods) &&
    item.bookedPeriods
      .map((e) => ({
        state: 'unavailable',
        range: moment.range(
          moment(e.startDate).subtract(1, 'days'),
          moment(e.endDate).add(1, 'days')
        ),
      }))
      .sort((a, b) =>
        moment(a.range.start).isBefore(moment(b.range.start)) ? -1 : 1
      )

  const handleSelect = (range, states) => {
    setDateRange({
      value: range,
      states: states,
    })
  }

  const daysOfRent = moment
    .duration(
      moment(dateRange.value.end)
        .startOf('day')
        .diff(moment(dateRange.value.start).startOf('day'))
    )
    .asDays()

  return (
    <>
      <Grid item xs={12} className={classes.wrapper}>
        <Card className={classes.card} key={item?.id}>
          {isLink ? (
            <CardMedia
              className={classes.media}
              image={`${process.env.REACT_APP_API_ENTRYPOINT}${item?.photos?.[0]?.url}`}
              title={item?.fullName}
              component={Link}
              to={`/order/step1/${item?.id}`}
            />
          ) : (
            <>
              <div className={classes.root}>
                {maxSteps > 0 && (
                  <>
                    <Paper square elevation={0} className={classes.header}>
                      <Typography>{tutorialSteps?.[activeStep]?.id}</Typography>
                    </Paper>

                    <img
                      className={classes.img}
                      src={`${process.env.REACT_APP_API_ENTRYPOINT}${tutorialSteps?.[activeStep]?.url}`}
                      alt={tutorialSteps?.[activeStep]?.id}
                    />
                    <MobileStepper
                      steps={maxSteps}
                      position="static"
                      variant="text"
                      activeStep={activeStep}
                      nextButton={
                        <Button
                          size="small"
                          onClick={handleNext}
                          disabled={activeStep === maxSteps - 1}
                        >
                          Następny
                          <KeyboardArrowRight />
                        </Button>
                      }
                      backButton={
                        <Button
                          size="small"
                          onClick={handleBack}
                          disabled={activeStep === 0}
                        >
                          <KeyboardArrowLeft />
                          Poprzedni
                        </Button>
                      }
                    />
                  </>
                )}
              </div>
            </>
          )}
          <CardContent>
            <Typography gutterBottom variant="headline" component="h2">
              {item?.model?.brand?.name} {item?.model?.name}
            </Typography>

            <Typography component="p">Cena: {plns}</Typography>

            {!isLink && (
              <Typography component="p">{item?.model?.description}</Typography>
            )}
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              className={classes.button}
              onClick={handleClickOpen}
            >
              Zarezerwuj
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <CloseIcon onClick={handleClose} className={classes.rightIcon} />
        </DialogTitle>
        <DialogContent>
          <DateRangePicker
            firstOfWeek={1}
            numberOfCalendars={2}
            selectionType="range"
            minimumDate={new Date()}
            stateDefinitions={stateDefinitions}
            dateStates={dateRanges}
            defaultState="available"
            showLegend={true}
            value={dateRange.value}
            onSelect={handleSelect}
          />
        </DialogContent>
        <DialogActions>
          {dateRange.value.start._d.getTime() !==
            dateRange.value.end._d.getTime() && (
            <>
              <Button
                onClick={() =>
                  addToCart({
                    ...item,
                    startDate: dateRange.value.start,
                    endDate: dateRange.value.end,
                    dateRanges: dateRanges,
                    daysOfRent: daysOfRent,
                  })
                }
                color="primary"
                variant="contained"
              >
                Zarezerwuj
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

ItemComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
}

export default withStyles(styles)(ItemComponent)
