import React from 'react'
import { useDispatch } from 'react-redux'
import { AppBarContent } from '@ui/shared'
import { authActions } from '@ui/shared'
import { AppBar as MuiAppBar, Toolbar, IconButton } from '@material-ui/core'
import { Phone } from '@material-ui/icons'
import { useStyles } from './AppBarStyles'

import { Title } from 'components/Title'

export const HomeAppBar = () => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(authActions.logout())
  }

  return (
    <MuiAppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <img className={classes.logo} src="/images/logo-f-sm.png" alt="logo" />
        <Title link={true}>PANEL KLIENTA</Title>
        <IconButton color="inherit" href="tel:+4822123456789">
          <Phone />
        </IconButton>

        <AppBarContent handlers={{ handleLogout }} />
      </Toolbar>
    </MuiAppBar>
  )
}
