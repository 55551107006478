import { Route, Redirect } from 'react-router-dom'
import { Home, LoginContainer, Settings, NotFound } from 'pages'
import { AnonymousRoute } from '@ui/shared'
import { PrivateRoute } from '@ui/shared'

const routes = () => [
  {
    type: PrivateRoute,
    component: Home,
    path: '/order',
  },

  {
    type: PrivateRoute,
    component: Settings,
    path: '/settings',
  },
  {
    type: AnonymousRoute,
    component: LoginContainer,
    path: '/login',
    exact: true,
  },
  {
    type: AnonymousRoute,
    component: LoginContainer,
    path: '/register',
    exact: true,
  },
  {
    type: AnonymousRoute,
    component: LoginContainer,
    path: '/security',
  },

  {
    type: Redirect,
    from: '/',
    to: '/order',
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '*',
  },
]

export default routes
