import React from 'react'

import { useDispatch } from 'react-redux'
import { authHeader } from '@ui/shared'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import ItemComponent from './ItemComponent'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  alert: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
})

function EquipmentComponent(props) {
  const { inventory, addToCart, updateDate, equipmentId, cart } = props

  const dispatch = useDispatch()

  function getData() {
    return (dispatch) => {
      fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}/fitness_equipments/${equipmentId}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            ...authHeader(),
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          dispatch({
            type: 'FETCH_DATA',
            inventory: res,
          })
        })
    }
  }

  React.useEffect(() => {
    dispatch(getData())
    // eslint-disable-next-line
  }, [])

  return (
    <ItemComponent
      item={inventory}
      addToCart={addToCart}
      updateDate={updateDate}
      cart={equipmentId && cart.find((x) => x.id === parseInt(equipmentId))}
    />
  )
}

EquipmentComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  inventory: PropTypes.array.isRequired,
  addToCart: PropTypes.func.isRequired,
}

export default withStyles(styles)(EquipmentComponent)
