import React from 'react'
import { prefixPaths } from '@ui/shared'
import { generateRoutes } from '@ui/shared'
import { DriveEta } from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import HomeComponent from './components/HomeComponent'
import { EquipmentWrapperComponent } from './components/EquipmentWrapperComponent'
import OrderComponent from './components/OrderComponent'
import OrderCompleteComponent from './components/OrderCompleteComponent'

const routes = () => [
  {
    title: 'Home',
    type: Route,
    component: HomeComponent,
    path: '/step1',
    exact: true,
    icon: DriveEta,
  },

  {
    title: 'Home',
    type: Redirect,
    from: '/',
    to: '/step1',
    exact: true,
  },
  {
    title: 'Order Complete',
    type: Route,
    component: OrderCompleteComponent,
    path: '/step3',
    exact: true,
  },
  {
    title: 'Order',
    type: Route,
    component: OrderComponent,
    path: '/step2',
    exact: true,
  },
  {
    title: 'Equipment',
    type: Route,
    component: EquipmentWrapperComponent,
    path: '/step1/:equipmentId',
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Home = (props) => {
  const { match } = props
  return (
    <Page
      routes={generateRoutes(
        prefixPaths(match.url, routes(), ['path', 'from', 'to'])
      )}
    />
  )
}
