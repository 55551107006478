import React from 'react'
import ShopItemContainer from '../../../containers/ShopItemContainer'
import CartContainer from '../../../containers/CartContainer'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  img: {
    width: '312px',
  },
  titleWrap: {
    paddingLeft: '5px',
    paddingTop: '5px',
  },
}))
export const EquipmentWrapperComponent = ({ match }) => {
  const classes = useStyles()
  const {
    params: { equipmentId },
  } = match

  return (
    <main className={classes.layout}>
      <Grid container>
        <Grid item xs={11}>
          <ShopItemContainer equipmentId={equipmentId} />
        </Grid>
        <Grid item xs={1} className={classes.cart}>
          <CartContainer />
        </Grid>
      </Grid>
    </main>
  )
}
